import { css } from 'emotion';
import { fadeIn } from 'styles/mixins/fade';

const styles = css`
	height: 100%;
	display: flex;
	flex-direction: column;
	.links {
		flex-shrink: 0;
		display: inline-flex;
		align-items: center;
		height: 50px;
		border-bottom: thin solid lightgrey;
		padding: 1rem;
		${fadeIn(600)}

		a {
			font-weight: normal;
		}
	}

	.loader {
		position: relative;
		left: 0;
		right: 0;
	}

	#webtext-page-elements {
		padding-bottom: 32px;
		overflow: auto;
		#no-questions {
			opacity: 0.65;
			font-weight: lighter;
			margin: 1rem;
		}

		.notebook-page-element {
			padding-top: 1rem;
			padding-bottom: 1rem;
			border-bottom: thin solid lightgrey;
		}

		hr {
			border-top: thin solid lightgrey;
		}
	}
	
	.MissingDependenciesMessage {
		margin-left: 1rem;
	}
`;

export default styles;
